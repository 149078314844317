import React from 'react';
import { Button } from 'react-bootstrap';

const SearchBar = ({ searchTerm, onSearchChange, onOpenEventModal}) => (
    <div className="col-12 col-lg-10 col-md-10 mb-2 mb-md-0">
        <input
            type="text"
            className="form-control search-input"
            placeholder="Search..."
            value={searchTerm}
            onChange={onSearchChange}
        />
    </div>
);

export default SearchBar;
