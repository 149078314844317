import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './nicepage.css';
import './Dashboard.css';
import axios from 'axios';
import DataTableComponent from './DataTableComponent';
import { RingLoader } from 'react-spinners';
import SearchBar from './SearchBar';
import CreativesModal from './CreativesModal';
import { Button } from 'react-bootstrap';
import Swal from 'sweetalert2';
import FeedbackModal from './FeedBackModal';
import Header from './Header';

export default function ShowPmCreative(){
    const [creativeData, setCreativeData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const location = useLocation() || null;
    const { eventId } = useParams();
    const navigate = useNavigate();
    const [currentCreativeId,setcurrentCreativeId] = useState(0);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(5);
    const [searchTerm, setSearchTerm] = useState('');
    const [showCreativesModal, setShowCreativesModal] = useState(false);
    const [showFeeback, setShowFeedback] = useState(false);
    const [feedBackReason,setfeedBackReason] = useState("");
    const [feedBackImg,setFeedbackImg] = useState("");
    const refreshData = async () => {
        const token = sessionStorage.getItem('token');
        if (!token) {
            navigate('/login');
            return;
        }

        setLoading(true);

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/getCreatives`, {event_id: eventId});

            const creatives = response.data;
            setCreativeData(creatives || []);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await axios.post(`${process.env.REACT_APP_API_URL}/getCreatives`, { event_id: eventId });
                const creatives = response.data;
                setCreativeData(creatives || []);
                // setImages(response.data.images || []);
                // setVideos(response.data.videos || []);
                // setOthers(response.data.others || []);
                // setFeedbackUrl(response.data.feedback_url || '');
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [eventId,currentCreativeId]);
    const handleFeedbackClick = (e,feedBackReason,feedBackImg) => {
        e.preventDefault();
        setShowFeedback(true);
        setFeedbackImg(feedBackImg);
        setfeedBackReason(feedBackReason);
       
    }
    const handleCloseFeedback = () => setShowFeedback(false);
    const handleDeleteClick = (e,creative_id) => {
        e.preventDefault();
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Delete it!',
            cancelButtonText: 'No, cancel!'
        }).then((result) => {
            if (result.isConfirmed) {
                // User confirmed rejection
                deleteCreative(creative_id);
                setcurrentCreativeId(creative_id);
                Swal.fire(
                    'Deleted!',
                    'Creative Deleted Successfully',
                    'success'
                )
               
                // Proceed with rejection logic here
            } else {
                // User canceled
                Swal.fire(
                    'Cancelled',
                    'Creative are safe :)',
                    'error'
                );
                // Handle cancellation if needed
            }
        });
    }
    const deleteCreative = async(creativeId) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/deleteCreatives`, 
            {
                id: creativeId,
            });
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
        }
    }
    useEffect(() => {
        console.log("creativedata = ", creativeData);
        const filtered = creativeData.filter(item =>
            item.Eventname.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.Execution.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredData(filtered);
        setCurrentPage(1);
    }, [searchTerm, creativeData]);
    const handlePageChange = page => {
        setCurrentPage(page);
    };
    
    
    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleLogout = () => {
        sessionStorage.removeItem('token');
        navigate('/login');
    };
    const pmName = location.state?.pmName || 'Unknown PM';
    const eventName = location.state?.eventName || '';
    const eventDate = location.state?.eventDate || '';
    const role = location.state?.role || 1;
    // const companyName = location.state?.companyName || 'Unknown Company';

    if (loading) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                <RingLoader color="#123abc" />
            </div>
        );
    }

    const customStyles = {
        headCells: {
            style: {
                backgroundColor: '#38077B',
                color: '#fff',
                fontSize: '16px',
                whiteSpace: 'normal', // Allows text to wrap in header cells
                overflow: 'visible',  // Ensures text is not clipped
                wordBreak: 'break-word', // Breaks long words
            },
        },
        cells: {
            style: {
                color: 'black',
                backgroundColor: 'white',
                fontSize: '16px',
                whiteSpace: 'normal', // Allows text to wrap in cells
                overflow: 'visible',  // Ensures text is not clipped
                wordBreak: 'break-word', // Breaks long words
            },
            highlightOnHoverStyle: {
                color: 'white',
                backgroundColor: '#478AC9',
            },
        },
    };
    const handleShowCreativesModal = (event) => {
        event.preventDefault();
        setShowCreativesModal(true);
    }
    const handleCloseCreativesModal = () => setShowCreativesModal(false);
    // Function to get column widths based on role
    const getColumnWidths = () => {
        return {
            eventName: "140px",
            dateOfExecution: "180px",
            clientName: "150px",
            Status : "150px",
            creativeType: "150px",
            actions: "250px",
            Feedback : "250px"
        };
    };

    // Get column widths based on the current role
    const columnWidths = getColumnWidths();

    // Define the columns based on the role
    const columns = [
        {
            name: 'Event Name',
            selector: row => row.Eventname,
            width: columnWidths.eventName,
            sortable: true,
            wrap: true
        },
        {
            name: 'Date of Execution',
            selector: row => row.Execution,
            width: columnWidths.dateOfExecution,
            sortable: true,
            wrap: true
        },
        {
            name: 'Client Name',
            selector: row => row.clientname,
            width: columnWidths.clientName,
            sortable: true,
            wrap: true
        },
        {
            name: 'Status',
            selector: row => row.approved > 0 ? 'Approved': 'Reject',
            width: columnWidths.Status,
            sortable: true,
            wrap: true
        },
        {
            name: 'Type',
            selector: row => row.creative_type,
            width: columnWidths.creativeType,
            sortable: true,
            wrap: true
        },
        {
            name: 'FeedBack',
            width: columnWidths.Feedback,
            cell: row => (
                row.feedback ? (
                    <div className="d-flex justify-content-space">
                        <a
                            href="#"
                            className='tableActionGreen'
                            title="FeedBack"
                            onClick={(e) => handleFeedbackClick(e,row.feedback,row.feedback_img_url)}
                        >
                            FeedBack
                        </a>
                    </div>
                ) : (
                    <div className="d-flex justify-content-space">
                        
                    </div>
                )
            ),
            sortable: true,
            wrap: true
        },
        {
            name: 'Actions',
            width: columnWidths.actions,
            cell: row => (
                <div className="d-flex justify-content-space">
                   
                    <a
                        href="#"
                        className='tableAction'
                        title="Delete"
                        onClick={(e) => handleDeleteClick(e, row.id)}
                    >Delete</a>
                </div>
            ),
        }
        
        
    ];

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
    return(
        <>
            <div className="u-body u-xl-mode" data-lang="en">
                <Header imagTag = "../../" role = {role} active = {{creatives:1}} />
                <section className="u-align-center u-clearfix u-section-1" id="sec-0e20">
                    <div className="u-clearfix u-sheet u-sheet-1">
                        <div className="data-layout-selected u-clearfix u-expanded-width u-layout-wrap u-palette-5-light-3 u-layout-wrap-1">
                            <div className="u-gutter-0 u-layout">
                                <div className="u-layout-row">
                                    
                                    <div className="u-align-center u-container-style u-layout-cell u-size-60 u-layout-cell-1">
                                        <div className="u-container-layout u-container-layout-1">
                                            <h4 className="u-align-center u-text u-text-1">PM : {pmName}</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container">
                            <div className="row align-items-center mt-2 mb-2">
                                <SearchBar searchTerm={searchTerm} onSearchChange={handleSearchChange} />
                                <div className = 'col-12 col-lg-2 col-md-2 mt-2 mb-2'><Button variant="outline-danger" size="lg" onClick={(e) => handleShowCreativesModal(e)}>
                                    Add New Creative
                                </Button></div>
                            </div>
                        </div>
                        <DataTableComponent
                            data={currentItems}
                            columns={columns}
                            loading={loading}
                            pagination={{ customStyles }}
                            paginationServer
                            paginationTotalRows={filteredData.length}
                            handlePageChange={handlePageChange}
                            itemsPerPage={itemsPerPage}
                        />
                        <CreativesModal
                                show={showCreativesModal}
                                handleClose={handleCloseCreativesModal}
                                eventId={eventId}
                                eventName={eventName}
                                eventDate={eventDate}
                                refreshData = {refreshData}
                        />
                        <FeedbackModal
                                show={showFeeback}
                                handleClose={handleCloseFeedback}
                                feedBackImg = {feedBackImg}
                                feedBackReason={feedBackReason}
                        />
                    </div>

                </section>
            </div>
        </>
    );
}