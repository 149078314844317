const PurchasePanel = ({ approvals,totalPendingApprovals}) => {
    return (
        <>
            <div className="container mt-1 mb-3">
                <div className="row justify-content-around">
                    <div className="col-md-5 col-12 col-lg-5 mb-3 rounded-4" style={{ backgroundColor: '#fc0000' }}>
                        <h5 className="text-center text-white">Total Pending Approvals</h5>
                        <h1 className="text-center text-white">{totalPendingApprovals}</h1>
                    </div>
                    <div className="col-md-5 col-12 col-lg-5 mb-3 rounded-4" style={{ backgroundColor: '#fc0000' }}>
                        <h5 className="text-center text-white">Total Approvals</h5>
                        <h1 className="text-center text-white">{approvals}</h1>
                    </div>
                </div>
            </div>
            <div class="u-align-center u-border-3 u-border-palette-1-base u-expanded-width u-line u-line-horizontal u-line-1">
            </div>
        </>
    )
}
export default PurchasePanel;