import React, { useState } from 'react';
import { useNavigate, useParams,useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './nicepage.css';
import './Dashboard.css';
import Header from './Header';
import axios from 'axios';
const ShowIclinicEvent = () => {
    const [loading,setLoading] = useState(false);
    const navigate = useNavigate();
    const { eventId } = useParams();
    const location = useLocation();
    const clientId = location.state?.clientId || '';
    const role = location.state?.role || '';
    const pmId = location.state?.pmId || '';
    const compId = location.state?.compId || '';
    const pmName = location.state?.pmName || '';
    const companyName = location.state?.companyName || '';
    const [selectedIclinic, setSelectedIclinic] = useState('');
    const [selectedIclinicDisplays, setselectedIclinicDisplays] = useState('');
    const [printingData,setPrintingData] = useState([]);
    const [Branding,setBranding] = useState([]);
    
    // Redirect to Estimate exhibition page when client click on exhibition button
    const handleExhibition = (e) => {
        e.preventDefault()
        console.log("handle = ",clientId);
        navigate(`/dashboard/showexhibitionevent/${eventId}`,
        {
            state : {
                        clientId: clientId,
                        role: role,
                        pmId: pmId,
                        compId : compId,
                        pmName : pmName,
                        companyName : companyName,
                    }
        });
    }
    // Redirect to Estimate event page when client click on EVENT button
     const handleEvent = (e) => {
        e.preventDefault();
        navigate(`/dashboard/showestimateseevent/${eventId}`,{
            state : {
                clientId: clientId,
                role: role,
                pmId: pmId,
                compId : compId,
                pmName : pmName,
                companyName : companyName,
            }
        });
    };

    const handleIclinicChange = (e) => {
        const value = e.target.value.trim();
        console.log("value = ",value);
        if(value.length > 0){
            setSelectedIclinic(value);
        }
        else{
            setSelectedIclinic('');
            setselectedIclinicDisplays('');
            setPrintingData([]); // Reset Printing Data value selection when iclinic change
             // Reset Display Value selection when Iclinic changes
        }
         
    };
    const fetchPrintingData = async(iclinicData) => {
        setLoading(true);
        try{
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/get_inclinic_printing`,{
                inclinic_data : selectedIclinic,
                inclinic_printing : iclinicData
            });  
            console.log("response = ",response);
            if(response.status == 200){
                setPrintingData(response.data.data || []); 
            }
            else{
                setPrintingData([]); 
            }
        }
        catch(err){
            setPrintingData([]);
        }
        finally{
            setLoading(false);
        }
    }
    const handleIclinicDisplays = (e) => {
        const data = e.target.value;
        setselectedIclinicDisplays(data);
        if(data.length > 0){
            fetchPrintingData(data);
        }
        else{
            setPrintingData([]);
        }
    };
    const handlePrintingData = (e) => {
        e.preventDefault();
        setBranding(e.target.value);
    }
    const handleNext = (e) => {
        console.log("clientId = ",clientId);
        e.preventDefault();
        navigate(`/dashboard/nexticlinicevent/${eventId}`,
        {
            state:
            {
                estimateType: 'INCLININC',
                selectedIclinic : selectedIclinic,
                selectedIclinicDisplays : selectedIclinicDisplays,
                Branding : Branding,
                role:role,
                pmId,pmId,
                clientId: clientId,
                compId : compId,
                pmName : pmName,
                companyName : companyName
            }
        });
    }
    return (
        <>
            <div className="u-body u-xl-mode" data-lang="en">
               <Header imagTag = '../../' role = {role} active = {{estimates:1}}/>
            </div>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-2">
                        <img
                            src="../../images/logo1.png"
                            alt="Logo"
                            className="img-fluid"
                            style={{ maxHeight: '75px' }}
                        />
                    </div>
                </div>
                <div className="card mt-4 border-0">
                    <div className="card-body">
                        <div className="row justify-content-center">
                            <div className="col-md-6 ms-4">
                                <a className="estimateButtonInActive" onClick={handleEvent}>Event</a>
                                <a className="estimateButtonInActive" onClick = {handleExhibition}>Conference/Exhibition</a>
                                <a className="estimateButtonActive">Inclinic Solution</a>
                            </div>
                        </div>
                        <h6 className="mt-5"><b>Select Branding & Marketing / Branding & Printing</b></h6>
                        <select className="form-select" onChange={handleIclinicChange} value = {selectedIclinic}>
                            <option value="">Select</option>
                            <option value="In-clinic Branding & Print Products">In-clinic Branding & Print Products</option>
                            <option value="Merchandising">Merchandising</option>
                        </select>
                        {selectedIclinic.length > 0 && (
                            <div className="mt-4">
                            <h6><b>Select In-clinic Displays</b></h6>
                            <select className="form-select" onChange={handleIclinicDisplays} value={selectedIclinicDisplays}>
                                {selectedIclinic === 'Merchandising' ? (
                                    <>
                                        <option value="">Select</option>
                                        <option value="Corporate gifting">Corporate gifting</option>
                                        <option value="Stationary">Stationary</option>
                                    </>
                                ) : (
                                    <>
                                        <option value="">Select</option>
                                        <option value="In-clinic Displays">In-clinic Displays</option>
                                        <option value="Display stands">Display stands</option>
                                        <option value="Commercial printing">Commercial printing</option>
                                        <option value="Short run digital printing">Short run digital printing</option>
                                        <option value="Large format digital printing">Large format digital printing</option>
                                    </>
                                )}
                            </select>
                          

                        </div>
                        )}
                        {loading && <div>Loading...</div>}
                        {
                            printingData.length > 0 && (
                                <div className="mt-4">
                                    <h6><b>Select Printing Data</b></h6>
                                    <select className="form-select" onChange={handlePrintingData}>
                                        <option value=''>Select Printing Data</option>
                                        {printingData.map((item, index) => (
                                            <option key={index} value={item.Branding}>{item.Branding}</option>
                                        ))}
                                    </select>
                                </div>
                            )
                        }
                    </div>
                    {Branding.length > 0 && (
                             <p>
                                <a className="estimateButtonInActive" onClick={(e) => {handleNext(e)}}>Next  --></a>
                            </p>
                    )}
                </div>
            </div>
        </>
    )
}
export default ShowIclinicEvent;