import React, { useState } from 'react';
import { Modal, Button, Form, Spinner,ProgressBar } from 'react-bootstrap';
import axios from 'axios';
import Swal from 'sweetalert2';

const CreativesModal = ({ show, handleClose, eventId, eventName, eventDate,refreshData }) => {
    const [creativeType, setCreativeType] = useState('');
    const [files, setFiles] = useState([]);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);
    const handleFileChange = (e) => {
        setFiles(e.target.files);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setProgress(0); 
        const formData = new FormData();
        formData.append('EventId', eventId);
        formData.append('creativeType', creativeType);  // Changed from 'creativeUrl' to 'creativeType'
        formData.append('eventName', eventName);
        formData.append('eventDate', eventDate);
        Array.from(files).forEach(file => formData.append('files[]', file));

        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/creatives`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                onUploadProgress: progressEvent => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    console.log("percentCompleted = ",percentCompleted);
                    setProgress(percentCompleted);
                }
            });
            Swal.fire({
                icon: 'success',
                title: 'Creatives Uploaded',
                text: 'Your creative content has been submitted successfully!',
            });
            setCreativeType('');  // Resetting the creativeType
            setFiles([]);
            handleClose();
            refreshData();
        } catch (err) {
            setError('Error uploading creatives. Please try again.');
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal show={show} onHide={handleClose} size="lg" centered>
            <Modal.Header closeButton>
                <Modal.Title>Upload Creatives</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {loading && (
                    <div>
                        <div className="d-flex justify-content-center">
                            <Spinner animation="border" />
                        </div>
                        <ProgressBar now={progress} label={`${progress}%`} className="mt-3" />
                    </div>
                )}
                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3">
                        <Form.Label>Creative Type</Form.Label> {/* Changed label */}
                        <Form.Control
                            type="text"
                            placeholder="Enter Creative Type"
                            value={creativeType}
                            onChange={(e) => setCreativeType(e.target.value)}  // Updated state handling
                            required
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Upload Files</Form.Label>
                        <Form.Control
                            type="file"
                            multiple
                            onChange={handleFileChange}
                            disabled={loading}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Control
                            type="hidden"
                            value={eventName}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Control
                            type="hidden"
                            value={eventDate}
                        />
                    </Form.Group>
                    {error && <p className="text-danger">{error}</p>}
                    <Button className="mt-3" variant="danger" type="submit" disabled={loading}>
                        {loading ? 'Submitting...' : 'Submit'}
                    </Button>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <img
                    src="../../images/logo1.png"
                    alt="Logo"
                    className="img-fluid"
                    style={{ maxHeight: '155px' }}
                />
            </Modal.Footer>
        </Modal>
    );
};

export default CreativesModal; 