import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './nicepage.css';
import './Dashboard.css';
import axios from 'axios';
import Header from './Header';
import SearchBar from './SearchBar';
import DataTableComponent from './DataTableComponent';
import ViewPoCopyModal from './ViewPoCopyModal';
import ViewInvoiceCopyModal from './ViewInvoiceCopyModal';
import UploadInvoiceCopyModal from './UploadInvoiceCopyModal';
const MoreEstimates = () => {
    const [estimateData, setEstimateData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(5);
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(false);
    const [showPoModal, setShowPoModal] = useState(false);
    const [showInvoiceModal, setShowInvoiceModal] = useState(false);
    const[showUploadInvoiceModal,setShowUploadInvoiceModal] = useState(false);
    const [poData, setPoData] = useState('');
    const [invoiceData, setInvoiceData] = useState('');

    const navigate = useNavigate();
    const location = useLocation();
    const { estimateId } = useParams();
    const eventId = location.state?.eventId || 0;
    const pmId = location.state?.pmId || 0;
    const pmName = location.state?.pmName || 'Unknown PM';
    const companyName = location.state?.companyName || 'Unknown Company';
    const role = location.state?.role || '';

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                console.log(estimateId);
                const params = { id: estimateId };
                let response = "";
                if (role == 1) {
                    response = await axios.get(`${process.env.REACT_APP_API_URL}/moreClientEstimate`, { params });
                } else {
                    response = await axios.get(`${process.env.REACT_APP_API_URL}/more_pm_estimate`, { params });
                }
                if (response.status === 200) {
                    const estimates = response.data.data;
                    console.log("estimates", estimates);
                    setEstimateData([estimates] || []);
                }
            } catch (err) {
                console.error(err);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [estimateId, role]);

    useEffect(() => {
        console.log("showPoModal = ",showPoModal);
        const filtered = estimateData.filter(item =>
            (item.id?.toString().toLowerCase().includes(searchTerm.toLowerCase()) || '') ||
            (item.date?.toString().toLowerCase().includes(searchTerm.toLowerCase()) || '')
        );
        setFilteredData(filtered);
        setCurrentPage(1);
    }, [searchTerm, estimateData]);

    useEffect(() => {
        // Reset modal state on component mount
        setShowPoModal(false);
        setShowInvoiceModal(false);
    }, []);

    const handleView = (e, role, estimateId) => {
        e.preventDefault();
        navigate(`/dashboard/showestimates/${eventId}/viewestimates/${estimateId}`, {
            state: {
                role: role,
                eventId: eventId,
                pmId: pmId,
                pmName: pmName,
                companyName: companyName,
                estimateType: estimateData[estimateId - 1]?.estimate_type,
                totalCost: estimateData[estimateId - 1]?.client_total,
                estimate_data_selected: estimateData[estimateId - 1]?.estimate_data_selected,
                disableApprove : true
            }
        });
    }

    const handleViewPoCopy = (e, upload_purchase_order) => {
        e.preventDefault();
        setPoData(upload_purchase_order);
        setShowPoModal(true);
    }

    const handleClosePoModal = () => {
        setShowPoModal(false);
    };

    const handleViewInvoiceCopy = (e, upload_invoice) => {
        e.preventDefault();
        setInvoiceData(upload_invoice);
        setShowInvoiceModal(true);
    }

    const handleCloseInvoiceModal = () => {
        setShowInvoiceModal(false);
    };

    const handleUploadInvoice = (e) => {
        e.preventDefault();
        setShowUploadInvoiceModal(true)
        // Handle upload invoice logic
    }
    const handleCloseUploadInvoice = () => {
        setShowUploadInvoiceModal(false);
    }
    const handlePageChange = page => {
        setCurrentPage(page);
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const customStyles = {
        headCells: {
            style: {
                backgroundColor: '#38077B',
                color: '#fff',
                fontSize: '16px',
                whiteSpace: 'normal',
                overflow: 'visible',
                wordBreak: 'break-word',
            },
        },
        cells: {
            style: {
                color: 'black',
                backgroundColor: 'white',
                fontSize: '16px',
                whiteSpace: 'normal',
                overflow: 'visible',
                wordBreak: 'break-word',
            },
            highlightOnHoverStyle: {
                color: 'white',
                backgroundColor: '#478AC9',
            },
        },
    };

    const getColumnWidths = () => {
        return {
            estimateNumber: "180px",
            date: "180px",
            clientApproval: "180px",
            purchaseApproval: "190px",
            actions: "660px",
        };
    };

    const columnWidths = getColumnWidths();

    const columns = [
        {
            name: 'Estimate Number',
            selector: row => row.id,
            width: columnWidths.estimateNumber,
            sortable: true,
            wrap: true
        },
        {
            name: 'Date of Creation',
            selector: row => row.date,
            width: columnWidths.date,
            sortable: true,
            wrap: true
        },
        {
            name: role == 1 ? 'status' : 'Client Approval',
            selector: row => row.status,
            width: columnWidths.clientApproval,
            sortable: true,
            wrap: true,
            cell: row => {
                let statusText = row.status;
                let buttonStyle = "";
                if (statusText === 'pending') {
                    buttonStyle = "btn btn-danger";
                } else if (statusText === 'Approved By Client') {
                    buttonStyle = "btn btn-success";
                } else if (statusText === 'change_by_pm') {
                    buttonStyle = "btn btn-warning text-white";
                } else {
                    statusText = 'R & C';
                    buttonStyle = "btn btn-info text-white";
                }
                return (
                    <button className={buttonStyle}>
                        {statusText}
                    </button>
                );
            }
        },
        {
            name: 'Purchase Approval',
            selector: row => { 
                let statusText = row.approvals;
                let buttonStyle = "";
                if (statusText === 'Approved By Purchase') {
                    buttonStyle = "btn btn-success";
                } 
                else{
                    statusText = 'Pending'
                    buttonStyle = "btn btn-danger";
                }
                return (
                        <button className = {buttonStyle}>
                            {statusText}
                        </button>   
                );
            },
            width: columnWidths.purchaseApproval,
            sortable: true,
            wrap: true
        },
        {
            name: 'Actions',
            width: columnWidths.actions,
            cell: row => (
                <div className="d-flex justify-content-space">
                    <a
                        href=""
                        className='tableAction1' style={{ width: '112px' }}
                        title="View"
                        onClick={(e) => { handleView(e, role, row.id) }}
                    >
                        VIEW
                    </a>
                    <a
                        href=""
                        className='tableAction1' style={{ width: '121px' }}
                        title="View PO Copy"
                        onClick={(e) => { handleViewPoCopy(e, row.upload_purchase_order) }}
                    >
                        VIEW PO COPY
                    </a>
                    {role == 1 ?
                        <a
                            href=""
                            className='tableAction1' style={{ width: '157px' }}
                            title="View Invoice Copy"
                            onClick={(e) => { handleViewInvoiceCopy(e, row.upload_invoice) }}
                        >
                            VIEW INVOICE COPY
                        </a> :
                        <a
                            href=""
                            className='tableAction1' style={{ width: '157px' }}
                            title="Upload Invoice"
                            onClick={(e) => { handleUploadInvoice(e, row.id) }}
                        >
                            UPLOAD INVOICE
                        </a>
                    }
                </div>
            ),
        }
    ];

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

    return (
        <>
            <div className="u-body u-xl-mode" data-lang="en">
                <Header imagTag='../../../../' />
                <section className="u-align-center u-clearfix u-section-1" id="sec-0e20">
                    <div className="u-clearfix u-sheet u-sheet-1">
                        <div className="data-layout-selected u-clearfix u-expanded-width u-layout-wrap u-palette-5-light-3 u-layout-wrap-1">
                            <div className="u-gutter-0 u-layout">
                                <div className="u-layout-row">
                                    {role == 1 ?
                                        <>
                                            <div className="u-align-center u-container-style u-layout-cell u-size-30 u-layout-cell-1">
                                                <div className="u-container-layout u-container-layout-1">
                                                    <h4 className="u-align-center u-text u-text-1">Company Name : {companyName}</h4>
                                                </div>
                                            </div>
                                            <div className="u-align-center u-container-style u-layout-cell u-size-30 u-layout-cell-1">
                                                <div className="u-container-layout u-container-layout-1">
                                                    <h4 className="u-align-center u-text u-text-1">PM : {pmName}</h4>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <div className="u-align-center u-container-style u-layout-cell u-size-60 u-layout-cell-1">
                                            <div className="u-container-layout u-container-layout-1">
                                                <h4 className="u-align-center u-text u-text-1">PM : {pmName}</h4>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="container">
                            <div className="row align-items-center mt-5 mb-5">
                                <SearchBar searchTerm={searchTerm} onSearchChange={handleSearchChange} />
                            </div>
                        </div>
                        <DataTableComponent
                            data={currentItems}
                            columns={columns}
                            loading={loading}
                            pagination={{ customStyles }}
                            paginationServer
                            paginationTotalRows={filteredData.length}
                            handlePageChange={handlePageChange}
                            itemsPerPage={itemsPerPage}
                        />
                        <ViewPoCopyModal
                            show={showPoModal}
                            handleClose={handleClosePoModal}
                            PoData={poData}
                        />
                        <ViewInvoiceCopyModal
                            show={showInvoiceModal}
                            handleClose={handleCloseInvoiceModal}
                            InvoiceData={invoiceData}
                        />
                        <UploadInvoiceCopyModal
                            show={showUploadInvoiceModal}
                            handleClose={handleCloseUploadInvoice}
                            estimateId = {estimateId}
                        >

                        </UploadInvoiceCopyModal>
                    </div>
                </section>
            </div>
        </>
    );
}

export default MoreEstimates;
