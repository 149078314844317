import React from 'react';
import { Modal } from 'react-bootstrap';

const ViewInvoiceCopyModal = ({ show, handleClose, InvoiceData }) => {
    return (
        <Modal show={show} onHide={handleClose} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>SHOW INVOICE COPY</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {InvoiceData != null ? (
                    <iframe src={InvoiceData} style={{ width: '100%', height: '400px' }}></iframe>
                ) : (
                    <p>No invoice Data Available!!!.</p>
                )}
            </Modal.Body>
        </Modal>
    );
}

export default ViewInvoiceCopyModal;
