import React, { useState } from 'react';
import { useNavigate, useParams,useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './nicepage.css';
import './Dashboard.css';
import Header from './Header';

const ShowExhibitionEvent = () => {
    const navigate = useNavigate();
    const { eventId } = useParams();
    const location = useLocation();
    const clientId = location.state?.clientId || '';
    const role = location.state?.role || '';
    const pmId = location.state?.pmId || '';
    const compId = location.state?.compId || '';
    const pmName = location.state?.pmName || '';
    const companyName = location.state?.companyName || '';
    const [selectedExhibition, setSelectedExhibition] = useState('');
    const [selectedExhibitionSize, setSelectedExhibitionSize] = useState('');
    const [selectedExhibitionSideOpen, setSelectedExhibitionSideOpen] = useState('');

    const handleExhibitionChange = (e) => {
        const value = e.target.value;
        setSelectedExhibition(value);
        setSelectedExhibitionSize(''); // Reset size selection when exhibition changes
        setSelectedExhibitionSideOpen(''); // Reset side open when exhibition changes
    };
    const handleExhibitionSize = (e) => {
        setSelectedExhibitionSize(e.target.value);
    };
    const handleExhibitionSideOpen = (e) => {
        setSelectedExhibitionSideOpen(e.target.value);
    };
    // Redirect to Estimate event page when client click on EVENT button
    const handleEvent = (e) => {
        e.preventDefault();
        navigate(`/dashboard/showestimateseevent/${eventId}`,{
            state : {
                clientId: clientId,
                role: role,
                pmId: pmId,
                compId : compId,
                pmName : pmName,
                companyName : companyName,
            }
        });
    };
    // Redirect to Estimate Iclinic page when client click on ICLINIC SOLUTION button
    const handleIclinic = (e) => {
        e.preventDefault();
        navigate(`/dashboard/showiclinicevent/${eventId}`,
            {
                state : {
                            clientId: clientId,
                            role: role,
                            pmId: pmId,
                            compId : compId,
                            pmName : pmName,
                            companyName : companyName,
                        }
            }
        );
    }
    const handleNext = (e) => {
        console.log("clientId = ",clientId);
        e.preventDefault();
        navigate(`/dashboard/nextexhibitionevent/${eventId}`,
        {
            state:
            {
                estimateType: 'EXHIBITION',
                selectedExhibition : selectedExhibition,
                selectedExhibitionSize : selectedExhibitionSize,
                selectedExhibitionSideOpen : selectedExhibitionSideOpen,
                role:role,
                pmId,pmId,
                clientId: clientId,
                compId : compId,
                pmName : pmName,
                companyName : companyName
            }
        });
    }
    return (
        <>
            <div className="u-body u-xl-mode" data-lang="en">
               <Header imagTag = '../../' role = {role} active = {{estimates:1}}/>
            </div>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-2">
                        <img
                            src="../../images/logo1.png"
                            alt="Logo"
                            className="img-fluid"
                            style={{ maxHeight: '75px' }}
                        />
                    </div>
                </div>
                <div className="card mt-4 border-0">
                    <div className="card-body">
                        <div className="row justify-content-center">
                            <div className="col-md-6 ms-4">
                                <a className="estimateButtonInActive" onClick={handleEvent}>Event</a>
                                <a className="estimateButtonActive">Conference/Exhibition</a>
                                <a className="estimateButtonInActive"  onClick = {(e) => handleIclinic(e)}>Inclinic Solution</a>
                            </div>
                        </div>
                        <h6 className="mt-5"><b>Select Exhibition Type</b></h6>
                        <select className="form-select" onChange={handleExhibitionChange} value={selectedExhibition}>
                            <option value="">Select</option>
                            <option value="Regional Stall Branding">Regional Stall Branding</option>
                            <option value="National Stall Branding">National Stall Branding</option>
                        </select>
                        {selectedExhibition && (
                            <div className="mt-4">
                                <h6><b>Select Exhibition Size</b></h6>
                                <select className="form-select" onChange={handleExhibitionSize} value={selectedExhibitionSize}>
                                    <option value="">Select</option>
                                    <option value="2m x 2m">2m x 2m</option>
                                    <option value="3m x 2m">3m x 2m</option>
                                    <option value="3m x 3m">3m x 3m</option>
                                    <option value="4m x 2m">4m x 2m</option>
                                    <option value="5m x 2m">5m x 2m</option>
                                    <option value="6m x 2m">6m x 2m</option>
                                    <option value="4m x 3m">4m x 3m</option>
                                    <option value="5m x 3m">5m x 3m</option>
                                    <option value="6m x 3m">6m x 3m</option>
                                    {selectedExhibition === 'National Stall Branding' && (
                                        <>
                                            <option value="6m x 4m">6m x 4m</option>
                                            <option value="8m x 4m">8m x 4m</option>
                                            <option value="9m x 6m">9m x 6m</option>
                                            <option value="10m x 6m">10m x 6m</option>
                                            <option value="10m x 8m">10m x 8m</option>
                                            <option value="12m x 9m">12m x 9m</option>
                                            <option value="12m x 12m">12m x 12m</option>
                                        </>
                                    )}
                                </select>
                            </div>
                        )}
                        {selectedExhibitionSize && (
                            <div className="mt-4">
                                <h6><b>Select Exhibition Side Open</b></h6>
                                <select className="form-select" onChange={handleExhibitionSideOpen} value={selectedExhibitionSideOpen}>
                                    <option value="">Select</option>
                                    <option value="1 Side Open">1 Side Open</option>
                                    <option value="2 Side Open">2 Side Open</option>
                                </select>
                            </div>
                        )}
                        {selectedExhibitionSideOpen && (
                             <p>
                                <a className="estimateButtonInActive" onClick={(e) => {handleNext(e)}}>Next  --></a>
                            </p>
                        )}
                        
                    </div>
                </div>
            </div>
        </>
    );
}

export default ShowExhibitionEvent;
