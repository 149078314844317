import React, { useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './nicepage.css';
import './Dashboard.css';
import Header from './Header';
import axios from 'axios';

export default function ShowEstimateEvent() {
    const [estimateData, setEstimateData] = useState([]);
    const [estimateChildData, setEstimateChildData] = useState([]);
    const [pax, setPax] = useState(0);
    const [estimateCommonData,setEstimateCommonData] = useState([]);
    const [fabriconStages, setFabriconStages] = useState([]);
    const [fabriconPlatforms, setFabriconPlatforms] = useState([]);
    const [selectedEstimate, setSelectedEstimate] = useState('');
    const [subChildEstimate, setSubChildEstimate] = useState([]);
    const [fabrication, setFabrication] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loading1, setLoading1] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [selectedText, setSelectedText] = useState("");
    const [selectedText1, setSelectedText1] = useState("");
    const navigate = useNavigate();
    const { eventId } = useParams();
    const location = useLocation();
    const role = location.state?.role || '';
    const pmId = location.state?.pmId || '';
    const clientId = location.state?.clientId || '';
    const compId = location.state?.compId || '';
    const pmName = location.state?.pmName || '';
    const companyName = location.state?.companyName || '';
    const fetchEstimateData = async (estimateValue) => {
        setLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/${estimateValue}`);
            setEstimateData(response.data || []);
        } catch (err) {
            console.error(err);
            setEstimateData([]);
        } finally {
            setLoading(false);
        }
    };

    const fetchEstimateChildData = async (paxValue) => {
        setLoading1(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/dropdown_values_for_both`);
            setEstimateChildData(response.data || []);
        } catch (err) {
            console.error(err);
            setEstimateChildData([]);
        } finally {
            setLoading1(false);
        }
    };
   // Redirect to Estimate exhibition page when client click on exhibition button
    const handleExhibition = (e) => {
        e.preventDefault()
        console.log("handle = ",clientId);
        navigate(`/dashboard/showexhibitionevent/${eventId}`,
        {
            state : {
                        clientId: clientId,
                        role: role,
                        pmId: pmId,
                        compId : compId,
                        pmName : pmName,
                        companyName : companyName,
                    }
        });
    }
    // Redirect to Estimate Iclinic page when client click on ICLINIC SOLUTION button
    const handleIclinic = (e) => {
        e.preventDefault();
        navigate(`/dashboard/showiclinicevent/${eventId}`,
            {
                state : {
                            clientId: clientId,
                            role: role,
                            pmId: pmId,
                            compId : compId,
                            pmName : pmName,
                            companyName : companyName,
                        }
            }
        );
    }
    const fetchEstimateSubChildData = async (childEstimateVal) => {
        console.log(childEstimateVal);
        setLoading2(true);
        try {
            const arr = ['1000', '150', '200', '250', '500'];
            const params = { pax };
            let fabricationData = [];
            let stagesData = [];
            let platformsData = [];
            let allEstimateData = [];
            for (const val of childEstimateVal) {
                let childEstimateUrl = '';
                if (val === 'Fabrication') {  // if estimate val is fabrication
                    childEstimateUrl = 'fabrication_stages';
                    if (arr.includes(pax)) { // for projector and led setup, if estimate val fabrication and pax value lie between [100,150,200,250,500]
                        const response = await axios.get(`${process.env.REACT_APP_API_URL}/fabrications`, { params });
                        if (response.data.length > 0) {
                            response.data.map((item, index) => (
                                fabricationData.push(response.data[index])
                            ))
                        }
                    } else {   // for projector and led setup, if estimate val fabrication and pax value lie between [50,100]
                        const [stagesResponse, platformsResponse] = await Promise.all([
                            axios.get(`${process.env.REACT_APP_API_URL}/${childEstimateUrl}`, { params }),
                            axios.get(`${process.env.REACT_APP_API_URL}/fabrication_platforms`, { params })
                        ]);
                        if (stagesResponse.data.length > 0) {
                            stagesResponse.data.map((item, index) => (
                                stagesData.push(stagesResponse.data[index])
                            ))
                        }
                        if (platformsResponse.data.length > 0) {
                            platformsResponse.data.map((item, index) => (
                                platformsData.push(platformsResponse.data[index])
                            ))
                        }
                    }
                } else {
                    // if estimate val not fabrication and pax value any
                    childEstimateUrl = val;
                    // Fetch data for other estimates
                    const response = await axios.get(`${process.env.REACT_APP_API_URL}/${childEstimateUrl}`, { params });
                    if (response.data.length > 0) {
                        response.data.map((item, index) => (
                            allEstimateData.push(response.data[index])
                        ))
                    }
                }
            }
            // Update state
            setFabrication(fabricationData);
            setFabriconStages(stagesData);
            setFabriconPlatforms(platformsData);
            setEstimateCommonData(allEstimateData);
            console.log("Fabrication data =", fabricationData);
            console.log("Stages data =", stagesData);
            console.log("Platforms data =", platformsData);
            console.log("allEstimateData  =", allEstimateData);

        } catch (err) {
            console.error(err);
            setFabriconStages([]);
            setFabriconPlatforms([]);
            setFabrication([]);
            setEstimateCommonData([]);
        } finally {
            setLoading2(false);
        }
    };

    const handleChange = async (e) => {
        const estimateValue = e.target.value;
        setSelectedEstimate(estimateValue);
        if (estimateValue.length > 0) {
            if (estimateValue == 'projector_setups') {
                setSelectedText('Projector Setup');
            }
            else {
                setSelectedText('Led Setup');
            }
            await fetchEstimateData(estimateValue);
            // Fetch child data after fetching estimate data
        } else {
            setEstimateData([]);
            setEstimateChildData([]);
            setFabriconStages([]);
            setFabriconPlatforms([]);
            setFabrication([]);
            setEstimateCommonData([]);
        }
    };


    const handleEstimateChange = (e) => {
        let data = e.target.value.replace("Pax", "").trim();
        if (selectedEstimate == 'ledsetups' && data == '100') {
            data = "1000"
        }
        setPax(data);
        if (data > 0) {
            fetchEstimateChildData(data);
        } else {
            setEstimateChildData([]);
            setFabriconStages([]);
            setFabriconPlatforms([]);
            setFabrication([]);
            setEstimateCommonData([]);
        }
    };

    const handleEstimateChildChange = (e) => {
        const selectedOptions = Array.from(e.target.options)
            .filter(option => option.selected)
            .map(option => option.value)
            .filter(value => value.trim() !== '')
            ;

        if (selectedOptions.length > 0) {
            setSelectedText1(selectedOptions.join(', '));
            fetchEstimateSubChildData(selectedOptions);
            console.log("estimateCommonData = ",estimateCommonData);
        } else {
            setFabriconStages([]);
            setFabriconPlatforms([]);
            setFabrication([]);
        }
    };
    const handleEstimateSubChildChange = (e) => {
        const options = e.target.options;
        const selectedValues = Array.from(options)
            .filter(option => option.selected)
            .map(option => option.value);
    
        const matchingItems = [];
        console.log("selected Values = ",selectedValues);
        if (selectedValues.length > 0) {
            if (fabrication.length > 0) {
                // Handle Fabrication data
                console.log("fabrication = ", fabrication);
                // Flatten and filter the fabrication data
                fabrication.forEach(entry => {
                    entry.inner_data.forEach(item => {
                        if (selectedValues.includes(item.id.toString())) {
                            matchingItems.push({
                                ...item,
                                image_url: entry.image_url
                            });
                        }
                    });
                });
                setSubChildEstimate(matchingItems);
            }if (fabriconStages.length > 0) {
                // Handle Fabricon Stages data
                const stagesMatchingItems = fabriconStages.filter(item =>
                    selectedValues.includes(item.id.toString())
                );
                console.log("Matching items (Fabricon Stages):", stagesMatchingItems);
                matchingItems.push(...stagesMatchingItems);
                setSubChildEstimate(matchingItems);
            }if (fabriconPlatforms.length > 0) {
                // Handle Fabricon Platforms data
                const platformsMatchingItems = fabriconPlatforms.filter(item =>
                    selectedValues.includes(item.id.toString())
                );
                console.log("Matching items (Fabricon Platforms):", platformsMatchingItems);
                matchingItems.push(...platformsMatchingItems);
                setSubChildEstimate(matchingItems);
            }
            if(estimateCommonData.length > 0){
                const platformsMatchingItems = estimateCommonData.filter(item =>
                    selectedValues.includes(item.id.toString())
                );
                console.log("Matching items (Common Data):", platformsMatchingItems);
                matchingItems.push(...platformsMatchingItems);
                setSubChildEstimate(matchingItems);
            }
        } else {
            setSubChildEstimate([]);
        }
    };
    const handleNext = (e) => {
        e.preventDefault();
        navigate(`/dashboard/nextestimatesevent/${eventId}`,
            {
                state:
                {
                    role: role,
                    pmId: pmId,
                    estimateType: 'ESTIMATE',
                    estimate_data_selected: subChildEstimate,
                    setup: selectedText,
                    pax: pax,
                    estimate_parent: selectedText1
                }
            });
    }
    return (
        <>
            <div className="u-body u-xl-mode" data-lang="en">
               <Header imagTag = '../../' role = {role} active = {{estimates:1}}/>
            </div>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-2">
                        <img
                            src="../../images/logo1.png"
                            alt="Logo"
                            className="img-fluid"
                            style={{ maxHeight: '75px' }}
                        />
                    </div>
                </div>

                <div className="card mt-4 border-0">
                    <div className="card-body">
                        <div className="row justify-content-center">
                            <div className="col-md-6 ms-4">
                                <a className="estimateButtonActive">Event</a>
                                <a className="estimateButtonInActive" onClick = {(e) => handleExhibition(e)}>Conference/Exhibition</a>
                                <a className="estimateButtonInActive" onClick = {(e) => handleIclinic(e)}>Inclinic Solution</a>
                            </div>
                        </div>

                        <h6 className="mt-5"><b>Select Event Type</b></h6>
                        <select className="form-select" onChange={handleChange} value={selectedEstimate}>
                            <option value=''>Select</option>
                            <option value="projector_setups">Projector Set Up</option>
                            <option value="ledsetups">Led Set Up</option>
                        </select>

                        {loading && <div>Loading...</div>}

                        {estimateData.length > 0 && (
                            <div className="mt-4">
                                <h6><b>Select Estimate Type</b></h6>
                                <select className="form-select" onChange={handleEstimateChange}>
                                    <option value=''>Select Estimate</option>
                                    {estimateData.map((item, index) => (
                                        <option key={index} value={item.value}>{item.value}</option>
                                    ))}
                                </select>
                            </div>
                        )}
                        {loading1 && <div>Loading...</div>}
                        {estimateChildData.length > 0 && (
                            <div className="mt-4">
                                <h6><b>Select Estimate Data</b></h6>
                                <select className="form-select" onChange={handleEstimateChildChange} multiple>
                                    <option value=''>Select Estimate Data</option>
                                    {estimateChildData.map((item, index) => (
                                        <option key={index} value={item.name}>{item.name}</option>
                                    ))}
                                </select>
                            </div>
                        )}
                        {loading2 && <div>Loading...</div>}
                        <div className="row">
                            {fabriconStages.length > 0 || fabriconPlatforms.length > 0 || fabrication.length > 0  ||estimateCommonData.length > 0 ? (
                                <div className="col-12 mt-4">
                                    <h6><b>Select EStimate</b></h6>
                                    <select className="form-select" multiple onChange={handleEstimateSubChildChange} size="15">
                                        
                                    {['1000', '150', '200', '250', '500'].includes(pax) ? (
                                        <>
                                            {fabrication.length > 0 && fabrication.map((item, index) => (
                                                <optgroup key={index} label={item.ELEMENT}>
                                                    {item.inner_data.map((innerItem, innerIndex) => (
                                                        <option key={innerIndex} value={innerItem.id}>
                                                            {innerItem.ELEMENT}
                                                        </option>
                                                    ))}
                                                </optgroup>
                                            ))}
                                        </>
                                    ) : (
                                            <>
                                                {fabriconStages.length > 0 && (
                                                    <optgroup label="Fabrication Stage">
                                                        {fabriconStages.map((item, index) => (
                                                            <option key={index} value={item.id}>
                                                                {item.ELEMENT}
                                                            </option>
                                                        ))}
                                                    </optgroup>
                                                )}
                                                {fabriconPlatforms.length > 0 && (
                                                    <optgroup label="Fabrication Platform">
                                                        {fabriconPlatforms.map((item, index) => (
                                                            <option key={index} value={item.id}>
                                                                {item.ELEMENT}
                                                            </option>
                                                        ))}
                                                    </optgroup>
                                                )}
                                            </>
                                        )}
                                           
                                       
                                        {estimateCommonData.length > 0 && (
                                            estimateCommonData.map((item, index) => (
                                                <option key={index} value={item.id}>
                                                    {item.ELEMENT}
                                                </option>
                                            ))
                                        )}
                                    </select>

                                </div>
                            ) : ''}
                        </div>
                        {subChildEstimate.length > 0 && (
                            <p>
                                <a className="estimateButtonInActive" onClick={(e) => { handleNext(e) }}>Next  --></a>
                            </p>

                        )}
                    </div>
                </div>
            </div>
        </>
    );
}
