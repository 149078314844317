import React, { useState } from 'react';
import { Modal, Button, Form, Spinner,ProgressBar } from 'react-bootstrap';
import axios from 'axios';
import Swal from 'sweetalert2';
const UploadInvoiceCopyModal = ({show,handleClose,estimateId}) => {
    const [files, setFiles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);
    const handleFileChange = (e) => {
        setFiles(e.target.files);
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setProgress(0); 
        const formData = new FormData();
        formData.append('id', estimateId);
        Array.from(files).forEach(file => formData.append('invoice', file));
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/uploadInvoice`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                onUploadProgress: progressEvent => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    console.log("percentCompleted = ",percentCompleted);
                    setProgress(percentCompleted);
                }
            });
            Swal.fire({
                icon: 'success',
                title: 'Invoice Copy Uploaded',
                text: 'Your invoice copy has been uploaded successfully!',
            });
            setFiles([]);
            handleClose();
        } catch (err) {
        } finally {
            setLoading(false);
        }
    };
    return (
        <>
            <Modal show={show} onHide={handleClose} size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Upload Invoice</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loading && (
                        <div>
                            <div className="d-flex justify-content-center">
                                <Spinner animation="border" />
                            </div>
                            <ProgressBar now={progress} label={`${progress}%`} className="mt-3" />
                        </div>
                    )}
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3">
                            <Form.Label>Upload Invoice</Form.Label>
                            <Form.Control
                                type="file"
                                multiple
                                onChange={handleFileChange}
                                disabled={loading}
                            />
                        </Form.Group>
                        <Button className="mt-3" variant="danger" type="submit" disabled={loading}>
                            {loading ? 'Submitting...' : 'Submit'}
                        </Button>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <img
                         src="../../../../images/logo1.png"
                        alt="Logo"
                        className="img-fluid"
                        style={{ maxHeight: '155px' }}
                    />
                </Modal.Footer>
            </Modal>
        </>
    )
}
export default UploadInvoiceCopyModal;