import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Header from './Header';
import SearchBar from './SearchBar';
import CompanyInfo from './CompanyInfo';
import PmPanel from './PmPanel';
import DataTableComponent from './DataTableComponent';
import EventModal from './EventModal';
import PurchasePanel from './PurchasePanel';
import UploadPoModal from './UploadPoModal';
import PostEventModal from './PostEventModal'; // Import the PostEventModal component
import { Button } from 'react-bootstrap';
import './nicepage.css';
import './Dashboard.css';
import CreativesModal from './CreativesModal';

const Dashboard = () => {
    const [userData, setUserData] = useState([]);
    const [estimateData,setEstimateData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(5);
    const [companyName, setCompanyName] = useState('');
    const [role,setRole] = useState(0);
    const [pmName, setPmName] = useState('');
    const [pmId, setPmId] = useState('');
    const [activeEvent, setActiveEvent] = useState(0);
    const [closeEvent, setInActiveEvent] = useState(0);
    const [estimateCount,setEstimateCount] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [showEventModal, setShowEventModal] = useState(false);
    const [showPostEventModal, setShowPostEventModal] = useState(false);
    const [currentEventId, setCurrentEventId] = useState(null);
    const [currentEventName, setcurrentEventName] = useState(null);
    const [currentEventDate, setcurrentEventDate] = useState(null);
    const [clientId, setClientId] = useState('');
    const [compId, setCompId] = useState('');
    const [approvals,setApporvals] = useState(0);
    const [totalPendingApprovals,setTotalPendingApprovals] = useState(0);
    const [showCreativesModal, setShowCreativesModal] = useState(false); // State for CreativesModal
    const [showUploadPo,setShowUploadPo] = useState(false);
    const [estimateId,setEstimateId] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            const token = sessionStorage.getItem('token');
            if (!token) {
                navigate('/login');
                return;
            }

            setLoading(true);

            try {
                const response = await axios.post(`${process.env.REACT_APP_API_URL}/dashboard`, {}, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });

                const { events,estimates, PMid,role,active_event,close_event,total_count,total_pending_approvals,approvals} = response.data.data;
                setPmId(PMid.PMid || '');
                setRole(role);
                setUserData(events || []);
                setEstimateData(estimates || []);
                setFilteredData(events || []);
                setCompanyName(PMid.CompanyName || 'Unknown Company');
                setPmName(PMid.PMname || 'Unknown PM');
                setActiveEvent(active_event|| 0);
                setInActiveEvent(close_event|| 0);
                setEstimateCount(total_count || 0);
                setClientId(PMid.clientId || ''); // Assuming `ClientId` is available from API
                setCompId(PMid.compId || '');     // Assuming `CompId` is available from API
                setApporvals(approvals || 0);
                setTotalPendingApprovals(total_pending_approvals || 0);
            } catch (error) {
                console.log(error.message);
                navigate('/login');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [navigate]);
    useEffect(() => {
        let filtered = [];
        if(role == 3){
            filtered = estimateData.filter(item => 
                item.Eventname.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.Eventdate.toLowerCase().includes(searchTerm.toLowerCase())
            );
        }
        else{
            filtered = userData.filter(item => 
                item.Eventname.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.Eventdate.toLowerCase().includes(searchTerm.toLowerCase())
            );
        }
        setFilteredData(filtered);
        setCurrentPage(1);
    }, [searchTerm, userData,estimateData]);
    const handlePageChange = page => {
        setCurrentPage(page);
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };
    const handleShowEventModal = () => setShowEventModal(true);
    const handleCloseEventModal = () => setShowEventModal(false);
    const handleShowPostEventModal = (event,eventId,eventName,eventDate) => {
        event.preventDefault();
        if(role == 1){
            navigate(`showpostevent/${eventId}`,
            {state:
                {
                    role:role,
                    pmName : pmName,
                    companyName : companyName
                }
            });
        }
        setCurrentEventId(eventId);
        setcurrentEventName(eventName);
        setcurrentEventDate(eventDate);
        setShowPostEventModal(true);
    };
    const handleUploadPo = (e,estimateId) => {
        e.preventDefault();
        setShowUploadPo(true);
        setEstimateId(estimateId);
    }
    const handleCloseUploadPo = () => setShowUploadPo(false);
    const handleClosePostEventModal = () => setShowPostEventModal(false);
    const handleShowEstimatesModal = (event, eventId, eventName, eventDate) => {
        event.preventDefault();
        console.log("dashboard = ",clientId);
        navigate(`showestimates/${eventId}`,
        {state:
            {
                eventId,eventId,
                role:role,
                pmId : pmId,
                compId : compId,
                pmName : pmName,
                companyName : companyName,
                clientId : clientId
            }
        });
        
    }
    const handleShowCreativesModal = (event, eventId, eventName, eventDate) => {
        event.preventDefault();
        if(role == 1){
            navigate(`showcreativeevent/${eventId}`,
            {state:
                {
                    role:role,
                    pmName : pmName,
                    companyName : companyName
                }
            });
        }
        else{
            setCurrentEventId(eventId);
            setcurrentEventName(eventName);
            setcurrentEventDate(eventDate);
            setShowCreativesModal(true);
            navigate(`showpmcreative/${eventId}`,
            {state:
                {
                    role:role,
                    pmName : pmName,
                    eventDate : eventDate,
                    eventName : eventName,
                }
            });
        }
       
    };

    const handleCloseCreativesModal = () => setShowCreativesModal(false);
    const refreshData = async () => {
        const token = sessionStorage.getItem('token');
        if (!token) {
            navigate('/login');
            return;
        }

        setLoading(true);

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/dashboard`, {}, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            const { events,estimates,total_pending_approvals,approvals} = response.data.data;
            setUserData(events || []);
            setFilteredData(events || []);
            setEstimateData(estimates || []);
            setApporvals(approvals || 0);
            setTotalPendingApprovals(total_pending_approvals || 0);
        } catch (error) {
            navigate('/login');
        } finally {
            setLoading(false);
        }
    };

    const customStyles = {
        headCells: {
            style: {
                backgroundColor: '#38077B',
                color: '#fff',
                fontSize: '16px',
                whiteSpace: 'normal', // Allows text to wrap in header cells
                overflow: 'visible',  // Ensures text is not clipped
                wordBreak: 'break-word', // Breaks long words
            },
        },
        cells: {
            style: {
                color: 'black',
                backgroundColor: 'white',
                fontSize: '16px',
                whiteSpace: 'normal', // Allows text to wrap in cells
                overflow: 'visible',  // Ensures text is not clipped
                wordBreak: 'break-word', // Breaks long words
            },
            highlightOnHoverStyle: {
                color: 'white',
                backgroundColor: '#478AC9',
            },
        },
    };
    
    // Function to get column widths based on role
const getColumnWidths = (role) => {
    if (role == 2) {
        return {
            eventName: "140px",
            dateOfExecution: "180px",
            clientName: "140px",
            status: "110px",
            actions: "635px",
        };
    }
    else if(role == 3){
        return {
            eventName: "140px",
            estimateNumber : "180px",
            dateOfCreation : "170px",
            clientApproval : "170px",
            purchasePanel : "170px",
            actions: "400px",

        }
    }
    return {
        eventName: "200px",
        dateOfExecution: "200px",
        actions: "740px",
    };
};

// Get column widths based on the current role
const columnWidths = getColumnWidths(role);

// Define the columns based on the role
const columns = [
    {
        name: 'Event Name',
        selector: row => row.Eventname,
        width: columnWidths.eventName,
        sortable: true,
    },
    ...(role == 1 || role == 2 ? [{
        name: 'Date of Execution',
        selector: row => row.Eventdate,
        width: columnWidths.dateOfExecution,
        sortable: true,
    },] : []),
    ...(role == 3 ? [
        {
            name: 'Estimate Number',
            selector: row =>  row.id,
            width: columnWidths.estimateNumber,
            sortable: true,
            wrap :true
        },
        {
            name: 'Date of Creation',
            selector: row => row.Eventdate,
            width: columnWidths.dateOfCreation,
            sortable: true,
            wrap:true
        },
        {
            name: 'Client Approval',
            selector: row =>  
                {
                    let statusText = row.status;
                    let buttonStyle = "";
                    if (statusText === 'pending') {
                        buttonStyle = "btn btn-danger";
                    } else if (statusText === 'Approved By Client') {
                        buttonStyle = "btn btn-success";
                    }
                    else if (statusText == 'change_by_pm'){
                        buttonStyle = "btn btn-warning text-white";
                    }
                    else{
                        statusText = 'R & C'
                        buttonStyle = "btn btn-info text-white";
                    }
                    return (
                            <button className = {buttonStyle} style = {{fontSize:'13px'}}>
                                {statusText}
                            </button>   
                    );
                },
            width: columnWidths.clientApproval,
            sortable: true,
            wrap:true
        },
        {
            name: 'Purchase Panel',
            width: columnWidths.purchasePanel,
            cell: row => {
                    let statusText = row.approvals;
                    let buttonStyle = "";
                    let fontSize  = "";
                    if (statusText === 'Approved By Purchase') {
                        buttonStyle = "btn btn-success";
                        fontSize = "11px";
                    } 
                    else{
                        statusText = 'Pending'
                        buttonStyle = "btn btn-danger";
                        fontSize = "13px";
                        
                    }
                    return (
                            <button className = {buttonStyle} style = {{fontSize:fontSize}}>
                                {statusText}
                            </button>   
                    );
            },
        },
    ] : []),
    ...(role == 2 ? [
        {
            name: 'Client Name',
            selector: row =>  row.clientname,
            width: columnWidths.clientName,
            sortable: true,
        },
        {
            name: 'Status',
            selector: row => row.status,
            width: columnWidths.status,
            sortable: true,
        }
    ] : []),
    ...(role == 1 || role == 2 ? [
        {
            name: 'Actions',
            width: columnWidths.actions,
            cell: row => (
                <div className="d-flex justify-content-space">
                    <a href="#" className="tableAction" title="View" onClick = {(e)=>{handleShowEstimatesModal(e, row.EventId, row.Eventname, row.Eventdate)}}>ESTIMATES</a>
                    <a href="#" className="tableAction" title="Edit" onClick={(e) => handleShowCreativesModal(e, row.EventId, row.Eventname, row.Eventdate)}>CREATIVES</a>
                    <a href="" className="tableAction" title="Post Event" onClick={(e) => handleShowPostEventModal(e,row.EventId,row.Eventname,row.Eventdate)}>POSTEVENT</a>
                </div>
            ),
        }
    ] : [{
        name: 'Actions',
        width: columnWidths.purchaseActions,
        cell: row => (
            <div className="d-flex justify-content-between">
                <a href="#" className="tableAction1" title="View">VIEW</a>
                <a href="#" className="tableAction1" title="Edit">EXPORT</a>
                <a href="" className="tableAction1" title="Post Event" onClick={(e) => handleUploadPo(e,row.id)} style = {{width:'100px'}}>UPLOAD PO</a>
            </div>
        ),
    }])
];

    // Calculate pagination
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
    // console.log("compId",compId);
    // return false;
    return (
        <>
        <div className="u-body u-xl-mode" data-lang="en">
            <Header role = {role} active = {{dashboard:1}} />

            <section className="u-align-center u-clearfix u-section-1" id="sec-0e20">
                <div className="u-clearfix u-sheet u-sheet-1">
                    {
                        role == 1 ? (
                            <CompanyInfo companyName={companyName} pmName={pmName} />
                        ) : role == 3 ? (
                            !loading ? <PurchasePanel approvals={approvals} totalPendingApprovals = {totalPendingApprovals}/> : ''
                        ) : !loading ? (
                            <PmPanel
                            pmName={pmName}
                            eventData={userData.length}
                            activeEvent={activeEvent}
                            closeEvent={closeEvent}
                            estimateCount={estimateCount}
                            />
                        ) : '' }
                    <div className="container mt-2 mb-2">
                        <div className="row align-items-center">
                            <SearchBar searchTerm={searchTerm} onSearchChange={handleSearchChange}/>
                            {role == 1 ? <div className = 'col-12 col-lg-2 col-md-2 mt-2 mb-2'><Button variant="outline-danger" size="lg" onClick={handleShowEventModal}>
                                Add New Event
                            </Button></div> : ''}
                        </div>
                    </div>
                    <EventModal
                        show={showEventModal}
                        handleClose={handleCloseEventModal}
                        clientId={clientId}
                        compId={compId}
                        refreshData={refreshData}
                    />
                    <PostEventModal
                        show={showPostEventModal}
                        handleClose={handleClosePostEventModal}
                        eventId={currentEventId}
                        eventName = {currentEventName}
                        eventDate = {currentEventDate}
                    />
                    <UploadPoModal 
                        show={showUploadPo}
                        handleClose={handleCloseUploadPo}
                        estimateId={estimateId}
                        refreshData={refreshData}
                    />
                    <CreativesModal
                            show={showCreativesModal}
                            handleClose={handleCloseCreativesModal}
                            eventId={currentEventId}
                            eventName={currentEventName}
                            eventDate={currentEventDate}
                    />
                    <DataTableComponent
                        data={currentItems}
                        columns={columns}
                        loading={loading}
                        pagination={{ customStyles }}
                        paginationServer
                        paginationTotalRows={filteredData.length}
                        handlePageChange={handlePageChange}
                        itemsPerPage={itemsPerPage}
                    />

                </div>
            </section>
        </div>
        <div className = "container-fluid mt-4" style = {{backgroundColor:"#333333",height:"250px"}}>
            <p className='text-center text-white p-4 m-5'>Sample text. Click to select the Text Element.</p>
            <p className = 'text-center text-white mt-5'>This site was created by Teams</p>
        </div>
        </>
    );
};

export default Dashboard;