import React,{useState} from 'react';
import { Modal,Form,Button,Spinner, ProgressBar} from 'react-bootstrap';
import axios from 'axios';
import Swal from 'sweetalert2';

export default function FeedbackModal({ show, handleClose, feedBackImg, feedBackReason }) {
    return (
        <Modal show={show} onHide={handleClose} size="lg" centered>
            <Modal.Header closeButton>
                <Modal.Title>Feedback Reason & Feedback Files</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-12 mb-3 border border-2 border-light rounded">
                        <p><b>{feedBackReason}</b></p>
                    </div>
                </div>
                <div className="text-center">
                    {feedBackImg ? (
                        <img src={feedBackImg} alt="Feedback" style={{ maxWidth: '100%', height: 'auto' }} />
                    ) : (
                        <p>No feedback image available</p>
                    )}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <img
                    src="../../images/logo1.png"
                    alt="Logo"
                    className="img-fluid"
                    style={{ maxHeight: '155px' }}
                />
            </Modal.Footer>
        </Modal>
    );
}
