import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './nicepage.css';
import './Dashboard.css';
import axios from 'axios';
import DataTableComponent from './DataTableComponent';
import { RingLoader } from 'react-spinners';
import SearchBar from './SearchBar';
import Swal from 'sweetalert2';
import Header from './Header';

export default function ShowCreativeEvent() {
    const [creativeData, setCreativeData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const location = useLocation() || null;
    const { eventId } = useParams();
    const navigate = useNavigate();
    const [approvereject, setApprovereject] = useState(0);
    const [currentCreativeId, setCurrentCreativeId] = useState(0);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(5);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await axios.post(`${process.env.REACT_APP_API_URL}/getCreatives`, { event_id: eventId });
                if (response.status === 200) {
                    const creatives = response.data;
                    setCreativeData(creatives || []);
                }
            } catch (err) {
                console.error('Error fetching creatives:', err);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [eventId, approvereject,currentCreativeId]); // Re-fetch when approvereject changes

    useEffect(() => {
        const filtered = creativeData.filter(item =>
            item.Eventname.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.Execution.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredData(filtered);
        setCurrentPage(1);
    }, [searchTerm, creativeData]);

    const handlePageChange = page => {
        setCurrentPage(page);
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };
    const handleDownload = async(e,creative_id,file_name) => {
        e.preventDefault();
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/downloadCreatives`, 
            { 
                creative_id: creative_id
            }, 
            { 
                responseType: 'blob' // Important to handle binary data
            }
        );
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download',file_name); // Set a default filename or handle dynamically if needed
        document.body.appendChild(link);
        link.click();

        // Clean up
        link.remove();
        window.URL.revokeObjectURL(url);
      
    }
    const pmName = location.state?.pmName || 'Unknown PM';
    const companyName = location.state?.companyName || 'Unknown Company';
    const role = location.state?.role || 1;
    if (loading) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                <RingLoader color="#123abc" />
            </div>
        );
    }

    const customStyles = {
        headCells: {
            style: {
                backgroundColor: '#38077B',
                color: '#fff',
                fontSize: '16px',
                whiteSpace: 'normal',
                overflow: 'visible',
                wordBreak: 'break-word',
            },
        },
        cells: {
            style: {
                color: 'black',
                backgroundColor: 'white',
                fontSize: '16px',
                whiteSpace: 'normal',
                overflow: 'visible',
                wordBreak: 'break-word',
            },
            highlightOnHoverStyle: {
                color: 'white',
                backgroundColor: '#478AC9',
            },
        },
    };

    const getColumnWidths = () => {
        return {
            eventName: "150px",
            dateOfExecution: "190px",
            fileName: "140px",
            creativeType: "110px",
            actions: "740px",
        };
    };

    const columnWidths = getColumnWidths();

    const columns = [
        {
            name: 'Event Name',
            selector: row => row.Eventname,
            width: columnWidths.eventName,
            sortable: true,
            wrap: true
        },
        {
            name: 'Date of Execution',
            selector: row => row.Execution,
            width: columnWidths.dateOfExecution,
            sortable: true,
            wrap: true
        },
        {
            name: 'File Name',
            selector: row => row.filename,
            width: columnWidths.fileName,
            sortable: true,
            wrap: true
        },
        {
            name: 'Type',
            selector: row => row.creative_type,
            width: columnWidths.creativeType,
            sortable: true,
            wrap: true
        },
        {
            name: 'Actions',
            width: columnWidths.actions,
            cell: row => (
                <div className="d-flex justify-content-space">
                    <a
                        href="#"
                        className={`${row.approved === 1 ? 'tableActionGreen' : 'tableAction'}`}
                        title="Approve"
                        onClick={(e) => { handleClick(e, 'approve', row.id) }}
                        style={{ pointerEvents: row.approved === 1 ? 'none' : 'auto' }}
                    >
                        APPROVE
                    </a>
                    <a
                        href="#"
                        className='tableAction'
                        title="Reject"
                        onClick={(e) => { handleClick(e, 'reject', row.id) }}
                        style={{ pointerEvents: row.rejected === 1 ? 'none' : 'auto' }}
                    >
                        Reject
                    </a>
                    <a href="" className="tableAction" title="Creative Event" onClick = {(e) => handleDownload(e,row.id,row.filename)}>DOWNLOAD</a>
                </div>
            ),
        }
    ];

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
    const handleClick = async (e, type, creativeId) => {
        e.preventDefault();
        if (type === 'approve') {
            const formData = new FormData();
            formData.append('id', creativeId);
            formData.append('approved', 1);
            formData.append('rejected', 0);
            setApprovereject(1);
            setCurrentCreativeId(creativeId);
            await creativeStatus(formData);
          
          
        } else {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, reject it!',
                cancelButtonText: 'No, cancel'
            }).then((result) => {
                if (result.isConfirmed) {
                    Swal.fire({
                        title: 'Provide a reason and upload a file',
                        html: `
                            <textarea id="reason" class="form-control" placeholder="Enter your reason here..."></textarea>
                            <div class = "mt-3">
                                <input type="file" id="fileUpload" class="form-control" />
                            </div>
                        `,
                        icon: 'info',
                        showCancelButton: true,
                        confirmButtonText: 'Submit',
                        cancelButtonText: 'Cancel',
                        preConfirm: () => {
                            const reason = Swal.getPopup().querySelector('#reason').value;
                            const fileInput = Swal.getPopup().querySelector('#fileUpload');
                            let file =  '';
                            if(fileInput.files.length > 0){
                                file = fileInput.files[0]
                            }
                            if (!reason) {
                                Swal.showValidationMessage('You need to provide a reason!');
                                return false;
                            }
                            const formData = new FormData();
                            formData.append('feedback', reason);
                            formData.append('feedback_img', file);
                            formData.append('id', creativeId);
                            formData.append('approved', 0);
                            formData.append('rejected', 1);

                            return formData;
                        }
                    }).then(async (result) => {
                        if (result.isConfirmed) {
                            const formData = result.value;
                            await creativeStatus(formData);
                            setCurrentCreativeId(creativeId);
                            setApprovereject(0);
                            Swal.fire(
                                'Rejected!',
                                'Your feedback and files have been rejected.',
                                'success'
                            );
                        } else {
                            Swal.fire(
                                'Cancelled',
                                'Your feedback and files are safe :)',
                                'error'
                            );
                        }
                    });
                } else {
                    Swal.fire(
                        'Cancelled',
                        'Your feedback and files are safe :)',
                        'error'
                    );
                }
            });
        }
    };

    const creativeStatus = async (formData) => {
        setLoading(true);
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/updateStatus1`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            // setApprovereject(prev => prev + 1); // Trigger data fetch again
        } catch (error) {
            console.error('Error updating status:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <div className="u-body u-xl-mode" data-lang="en">
                <Header imagTag = "../../" role = {role} active={{creatives:1}}/>
                <section className="u-align-center u-clearfix u-section-1" id="sec-0e20">
                    <div className="u-clearfix u-sheet u-sheet-1">
                        <div className="data-layout-selected u-clearfix u-expanded-width u-layout-wrap u-palette-5-light-3 u-layout-wrap-1">
                            <div className="u-gutter-0 u-layout">
                                <div className="u-layout-row">
                                    <div className="u-align-center u-container-style u-layout-cell u-size-30 u-layout-cell-1">
                                        <div className="u-container-layout u-container-layout-1">
                                            <h4 className="u-align-center u-text u-text-1">Company : {companyName}</h4>
                                        </div>
                                    </div>
                                    <div className="u-align-center u-container-style u-layout-cell u-size-30 u-layout-cell-1">
                                        <div className="u-container-layout u-container-layout-1">
                                            <h4 className="u-align-center u-text u-text-1">PM : {pmName}</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container">
                            <div className="row align-items-center mt-2 mb-2">
                                <SearchBar searchTerm={searchTerm} onSearchChange={handleSearchChange} />
                            </div>
                        </div>
                        <DataTableComponent
                            data={currentItems}
                            columns={columns}
                            loading={loading}
                            pagination={{ customStyles }}
                            paginationServer
                            paginationTotalRows={filteredData.length}
                            handlePageChange={handlePageChange}
                            itemsPerPage={itemsPerPage}
                        />
                    </div>
                </section>
            </div>
        </>
    );
}