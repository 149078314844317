import React from 'react';
import { Modal} from 'react-bootstrap';
const ViewPoCopyModal = ({show, handleClose,PoData}) => {
    return (
        <Modal show={show} onHide={handleClose} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>SHOW PO COPY</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {PoData != null ? (
                    <iframe src={PoData} style={{ width: '100%', height: '400px' }}></iframe>
                ) : (
                    <p>No Po Data Available!!!.</p>
                )}
            </Modal.Body>
        </Modal>
    )
}
export default ViewPoCopyModal;