import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Register from './components/Register';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import ProtectedRoute from './components/ProtectedRoute'; // Import the ProtectedRoute component
import ShowPostEvent from './components/ShowPostEvent';
import ShowCreativeEvent from './components/ShowCreativeEvent';
import ShowPmCreativeEvent from './components/ShowPmCreative';
import ShowEstimateEvent from './components/ShowEstimateEvent';
import ShowExhibitionEvent from './components/ShowExhibitionEvent';
import ShowIclinicEvent from './components/ShowIclinicEvent';
import ShowPmEstimateEvent from './components/ShowPmEstimateEvent';
import NextEstimateEvent from './components/NextEstimateEvent';
import NextExhibitionEvent from './components/NextExhibitionEvent';
import NextIclinicEvent from './components/NextIclinicEvent';
import ViewEstimates from './components/ViewEstimates';
import MoreEstimates from './components/MoreEstimates';
import ExhibitionCartEvent from './components/ExhbitionCartEvent'
const App = () => {
    return (
        <Router>
            <Routes>
                <Route path="/register" element={<Register />} />
                <Route path="/login" element={<Login />} />
                <Route path="/" element={<Login />} />
                <Route path="/dashboard" element={<ProtectedRoute element={Dashboard} />} />
                <Route path="/dashboard/showpostevent/:eventId" element={<ProtectedRoute element={ShowPostEvent} />} />
                <Route path="/dashboard/showcreativeevent/:eventId" element={<ProtectedRoute element={ShowCreativeEvent} />} />
                <Route path="/dashboard/showpmcreative/:eventId" element={<ProtectedRoute element={ShowPmCreativeEvent} />} />
                <Route path="/dashboard/showestimates/:eventId" element={<ProtectedRoute element={ShowPmEstimateEvent} />} />
                <Route path="/dashboard/showestimateseevent/:eventId" element={<ProtectedRoute element={ShowEstimateEvent} />} />
                <Route path="/dashboard/showexhibitionevent/:eventId" element={<ProtectedRoute element={ShowExhibitionEvent} />} />
                <Route path="/dashboard/showiclinicevent/:eventId" element={<ProtectedRoute element={ShowIclinicEvent} />} />
                <Route path="/dashboard/nextestimatesevent/:eventId" element={<ProtectedRoute element={NextEstimateEvent} />} />
                <Route path="/dashboard/nextexhibitionevent/:eventId" element={<ProtectedRoute element={NextExhibitionEvent} />} />
                <Route path="/dashboard/nexticlinicevent/:eventId" element={<ProtectedRoute element={NextIclinicEvent} />} />
                <Route path="/dashboard/editestimatesevent/:eventId" element={<ProtectedRoute element={NextEstimateEvent} />} />
                <Route path="/dashboard/cartitems/:eventId" element={<ProtectedRoute element={ExhibitionCartEvent} />} />
                <Route path="/dashboard/showestimates/:eventId/viewestimates/:estimateId" element={<ProtectedRoute element={ViewEstimates} />} />
                <Route path="/dashboard/showestimates/:eventId/moreestimates/:estimateId" element={<ProtectedRoute element={MoreEstimates} />} />
                

            </Routes>
        </Router>
    );
};

export default App;
