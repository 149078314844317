const PmPanel = ({ pmName,eventData,activeEvent,closeEvent,estimateCount }) => {
    return (
        <>
            <div className="data-layout-selected u-clearfix u-expanded-width u-layout-wrap u-palette-5-light-3 u-layout-wrap-1">
                <div className="u-gutter-0 u-layout">
                    <div className="u-layout-row">
                        <div className="u-align-center u-container-style u-layout-cell u-size-60 u-layout-cell-1">
                            <div className="u-container-layout u-container-layout-1">
                                <h4 class="u-align-center u-text u-text-1">PM : {pmName}</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="u-align-center u-border-3 u-border-palette-1-base u-expanded-width u-line u-line-horizontal u-line-1">
            </div>
            <div className="container mt-1 mb-3">
                <div className="row justify-content-around">
                    <div className="col-md-5 col-12 col-lg-2 mb-3 rounded-4" style={{ backgroundColor: '#38077b' }}>
                        <h5 className="text-center text-white">Total Event</h5>
                        <h1 className="text-center text-white">{eventData}</h1>
                    </div>
                    <div className="col-md-5 col-12 col-lg-2 mb-3 rounded-4" style={{ backgroundColor: '#fc0000' }}>
                        <h5 className="text-center text-white">Total Estimate</h5>
                        <h1 className="text-center text-white">{estimateCount}</h1>
                    </div>
                    <div className="col-md-5 col-12 col-lg-2 mb-3 rounded-4" style={{ backgroundColor: '#195203' }}>
                        <h5 className="text-center text-white">Active Event</h5>
                        <h1 className="text-center text-white">{activeEvent}</h1>
                    </div>
                    <div className="col-md-5 col-12 col-lg-2 mb-3 rounded-4" style={{ backgroundColor: '#fc5c00' }}>
                        <h5 className="text-center text-white">Close Event</h5>
                        <h1 className="text-center text-white">{closeEvent}</h1>
                    </div>
                </div>
            </div>


        </>

    )
}
export default PmPanel;